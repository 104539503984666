// WALLET
export const WALLET_ADD_NEW = 'WALLET_ADD_NEW';
export const WALLET_ADD_NEW_SUCCESS = 'WALLET_ADD_NEW_SUCCESS';
export const WALLET_ADD_NEW_ERROR = 'WALLET_ADD_NEW_ERROR';

export const WALLET_GET_PAGING = 'WALLET_GET_PAGING';
export const WALLET_GET_PAGING_SUCCESS = 'WALLET_GET_PAGING_SUCCESS';
export const WALLET_GET_PAGING_ERROR = 'WALLET_GET_PAGING_ERROR';

export const WALLET_GET_BY_ID = 'WALLET_GET_BY_ID';
export const WALLET_GET_BY_ID_SUCCESS = 'WALLET_GET_BY_ID_SUCCESS';
export const WALLET_GET_BY_ID_ERROR = 'WALLET_GET_BY_ID_ERROR';

export const WALLET_UPDATE = 'WALLET_UPDATE';
export const WALLET_UPDATE_SUCCESS = 'WALLET_UPDATE_SUCCESS';
export const WALLET_UPDATE_ERROR = 'WALLET_UPDATE_ERROR';

export const WALLET_DELETE = 'WALLET_DELETE';
export const WALLET_DELETE_SUCCESS = 'WALLET_DELETE_SUCCESS';
export const WALLET_DELETE_ERROR = 'WALLET_DELETE_ERROR';

import React, { useState, useEffect } from "react";
import { Button, Col, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import UserSelect from "containers/user/UserSelect";
import CurrencySelect from "containers/currency/currencySelect";

const ModalForm = ({ isOpen, toggle, title, onSubmit, item: propItem }) => {
  const [item, setItem] = useState({
    id: NIL,
    userId: null,
    balance: 0,
    currencyId: '',
  });

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (propItem) {
      const { userId, balance, currencyId, user } = propItem;
      setItem({
        id: propItem.id,
        userId: userId ? { key: userId, value: userId, label: user } : null,
        balance: balance ?? 0,
        currencyId: currencyId ?? '',
      });
    } else {
      setItem({
        id: NIL,
        userId: null,
        balance: 0,
        currencyId: '',
      });
    }
    setSubmitted(false);
  }, [propItem, isOpen]);

  const onOpened = () => {
    if (propItem) {
      const { userId, balance, currencyId } = propItem;
      setItem({
        ...propItem,
        userId: userId ? { key: userId, value: userId, label: propItem.user } : null,
        balance: balance ?? 0,
        currencyId: currencyId ?? '',
      });
    } else {
      setItem({
        id: NIL,
        userId: null,
        balance: 0,
        currencyId: '',
      });
      setSubmitted(false);
    }
  };

  const handleSubmit = () => {
    setSubmitted(true);
    const isValid = item.userId && item.currencyId;

    if (isValid) {
      const { userId, balance, currencyId } = item;
      const data = {
        ...item,
        userId: userId?.value ?? null,
        currencyId: currencyId?.value ?? null,
      };

      onSubmit(data);
    } 
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setItem({
      ...item,
      [name]: selectedOption
    });
  };

  const handleChange = ({ target: { name, value } }) => {
    if (name === "balance") {
      const numericValue = value === "" ? "" : parseFloat(value);
      if (!isNaN(numericValue) || value === "") {
        setItem({
          ...item,
          [name]: numericValue,
        });
      }
    } else {
      setItem({
        ...item,
        [name]: value,
      });
    }
  };

  return (
    <Modal
      size="md"
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {title}
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label>User</Label>
              <UserSelect
                name="userId"
                value={item.userId}
                roleNames={[]}
                onChange={handleSelectChange}
                className={submitted && !item.userId ? "is-invalid" : ""}
              />
            </FormGroup>
            <FormGroup>
              <Label>Currency</Label>
              <CurrencySelect
                name="currencyId"
                value={item.currencyId}
                onChange={handleSelectChange}
                className={submitted && !item.currencyId ? "is-invalid" : ""}
              />
              {submitted && !item.currencyId && (
                <div className="invalid-feedback-custom">Currency is required.</div>
              )}
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

ModalForm.propTypes = {
  item: PropTypes.object,
};

export default ModalForm;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { currencyService } from 'services/currency-service';
import Select, { components } from "react-select";
import { debounce } from "lodash";

const customSingleValue = ({
  children,
  data,
  ...props
}) => {
  const { label, symbol } = data;
  return <components.SingleValue { ...props }>{ label } | { symbol }</components.SingleValue>
};

const formatOptionLabel = ({ label, symbol, id }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p className="mb-0 font-size-13">
        <span className="me-2">Label</span>:<span className="ms-2">{ label }</span>
      </p>
      <p className="mb-0 font-size-13">
        <span className="me-2">Symbol</span>:<span className="ms-2">{ symbol }</span>
      </p>
    </div>
  );
};

const CurrencySelect = props => {
  const { 
    name,
    placeholder,
    value,
    onChange,
    isDisabled 
  } = props;

  const [loading, setLoading] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [term, setTerm] = useState('');

  const debouncedSearch = useCallback(
    debounce(term => {
      setTerm(term);
    }, 500),
    []
  );

  useEffect(() => {
    setLoading(true);
    currencyService.getRequest({ term }).then(records => {
      if (records) {
        setCurrencyOptions(records.map(currency => ({
          value: currency.id,
          label: currency.label,  
          symbol: currency.symbol, 
        })));
        setLoading(false);
      }
    });
  }, [term]);

  return (
    <Select
      name={ name }
      value={ value }
      onChange={ onChange }
      options={ currencyOptions }
      components={{ SingleValue: customSingleValue }}
      onInputChange={ value => debouncedSearch(value) }
      formatOptionLabel={ formatOptionLabel }
      classNamePrefix="select2-selection"
      placeholder={ placeholder }
      isDisabled={ isDisabled }
      isLoading={ loading }
      isClearable
    />
  );
};

CurrencySelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
};

export default CurrencySelect;

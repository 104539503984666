import {
    WALLET_ADD_NEW,
    WALLET_ADD_NEW_ERROR,
    WALLET_ADD_NEW_SUCCESS,
    WALLET_DELETE,
    WALLET_DELETE_ERROR,
    WALLET_DELETE_SUCCESS,
    WALLET_GET_BY_ID,
    WALLET_GET_BY_ID_ERROR,
    WALLET_GET_BY_ID_SUCCESS,
    WALLET_GET_PAGING,
    WALLET_GET_PAGING_ERROR,
    WALLET_GET_PAGING_SUCCESS,
    WALLET_UPDATE,
    WALLET_UPDATE_ERROR,
    WALLET_UPDATE_SUCCESS,
  } from './actionType';
  
  // Wallet
  export const getPagingWallet = items => ({
    type: WALLET_GET_PAGING,
    payload: items,
  });
  
  export const getPagingWalletSuccess = items => ({
    type: WALLET_GET_PAGING_SUCCESS,
    payload: items,
  });
  
  export const getPagingWalletError = error => ({
    type: WALLET_GET_PAGING_ERROR,
    payload: error,
  });
  
  export const addWallet = items => ({
    type: WALLET_ADD_NEW,
    payload: items,
  });
  
  export const addWalletSuccess = items => ({
    type: WALLET_ADD_NEW_SUCCESS,
    payload: items,
  });
  
  export const addWalletError = error => ({
    type: WALLET_ADD_NEW_ERROR,
    payload: error,
  });
  
  export const getWalletById = items => ({
    type: WALLET_GET_BY_ID,
    payload: items,
  });
  
  export const getWalletByIdSuccess = items => ({
    type: WALLET_GET_BY_ID_SUCCESS,
    payload: items,
  });
  
  export const getWalletByIdError = error => ({
    type: WALLET_GET_BY_ID_ERROR,
    payload: error,
  });
  
  export const updateWallet = items => ({
    type: WALLET_UPDATE,
    payload: items,
  });
  
  export const updateWalletSuccess = items => ({
    type: WALLET_UPDATE_SUCCESS,
    payload: items,
  });
  
  export const updateWalletError = error => ({
    type: WALLET_UPDATE_ERROR,
    payload: error,
  });
  
  export const deleteWallet = items => ({
    type: WALLET_DELETE,
    payload: items,
  });
  
  export const deleteWalletSuccess = items => ({
    type: WALLET_DELETE_SUCCESS,
    payload: items,
  });
  
  export const deleteWalletError = error => ({
    type: WALLET_DELETE_ERROR,
    payload: error,
  });
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  WALLET_ADD_NEW,
  WALLET_DELETE,
  WALLET_GET_BY_ID,
  WALLET_GET_PAGING,
  WALLET_UPDATE,
} from './actionType';

import {
  addWalletSuccess,
  addWalletError,
  getPagingWalletSuccess,
  getPagingWalletError,
  getWalletByIdSuccess,
  getWalletByIdError,
  updateWalletSuccess,
  deleteWalletSuccess,
} from './actions';
import { del, get, post, put as update } from "../../helpers/api_helper";
import { getWalletFail } from 'store/actions';

const getPagingWalletRequest = query => get("/api/Wallet/paging", { params: query });
const addNewWalletRequest = data => post("/api/Wallet", data);
const getWalletByIdRequest = id => get(`/api/Wallet/${id}`);
const updateWalletRequest = data => update("/api/Wallet", data);
const deleteWalletRequest = id => del(`/api/Wallet/${id}`);

function* addNewWallet({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(addNewWalletRequest, data);
    yield put(addWalletSuccess(response));

    const walletsResponse = yield call(getPagingWalletRequest, queryParams);
    yield put(getPagingWalletSuccess(walletsResponse));

  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    
    yield put(addWalletError(err));
  }
}

function* updateWallet({ payload }) {
  try {
    let response = yield call(updateWalletRequest, payload);
    response = yield call(getWalletByIdRequest, response);
    yield put(getWalletByIdSuccess(response));
    yield put(updateWalletSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getWalletFail(err));
  }
}

function* getPagingWallet({ payload }) {
  try {
    let response = yield call(getPagingWalletRequest, payload);
    yield put(getPagingWalletSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getPagingWalletError(err));
  }
}

function* getWalletById({ payload }) {
  try {
    let response = yield call(getWalletByIdRequest, payload);
    yield put(getWalletByIdSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getWalletByIdError(err));
  }
}

function* deleteWallet({ payload }) {
  try {
    const { data, queryParams } = payload;
    let response = yield call(deleteWalletRequest, data);

    yield put(deleteWalletSuccess(response));
    response = yield call(getPagingWalletRequest, queryParams);
    yield put(getPagingWalletSuccess(response));
  } catch (error) {
    const errorMessage = error.response?.data?.message;
    const errorJSON = error.response?.data?.errors;
    const err = errorMessage ? errorMessage : errorJSON ? JSON.stringify(errorJSON) : 'Something was wrong';
    yield put(getPagingWalletError(err));
  }
}

export default function* rootSaga() {
  yield takeEvery(WALLET_ADD_NEW, addNewWallet);
  yield takeEvery(WALLET_UPDATE, updateWallet);
  yield takeEvery(WALLET_GET_PAGING, getPagingWallet);
  yield takeEvery(WALLET_GET_BY_ID, getWalletById);
  yield takeEvery(WALLET_DELETE, deleteWallet);
}

export {
  getPagingWalletRequest
}

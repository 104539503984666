import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useCallback, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Input,
  Row,
} from "reactstrap";

import {
  getPagingWallet,
  addWallet,
  updateWallet,
  getWalletById,
  deleteWallet,
} from "store/actions";

import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import { debounce } from "lodash";
import ModalForm from "containers/wallet/ModalForm";
import WalletTable from "containers/wallet/WalletTable";
import UserSelect from "../../containers/user/UserSelect";

const WalletList = ({
  getPagingWallet,
  addWallet,
  deleteWallet,
  history,
  wallet: { items, loading, itemCount, pageCount },
}) => {
  const [id, setId] = useState(null);
  const [term, setTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen] = useState(false);
  const [ userId, setUserId ] = useState(null);

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term);
  }, 500), []);

  useEffect(() => {
    getPagingWallet({ 
      page, 
      pageSize, 
      term, 
      userId: userId ? userId.value : null
    });
  }, [getPagingWallet, page, pageSize, term, userId]);

  const handleOnEdit = (id) => {
    history.push(`/wallet/${id}`);
  };
  
  const handleOnAddNew = () => {
    setModalAddIsOpen(true);
  };

  const handleSubmit = (data) => {
    addWallet({ data, queryParams: {} });
    setModalAddIsOpen(false);
  };

  const handleConfirmDelete = (id) => {
    setId(id);
    setModalConfirmDeleteIsOpen(true);
  };

  const handleDelete = () => {
    deleteWallet({ data: id, queryParams: {} });
    setModalConfirmDeleteIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Wallet | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Wallet" breadcrumbItem="Wallet List" />
          <Row>
            <Col md={12}>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={2}>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={(e) => debouncedFetchData(e.target.value)}
                          />
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={4}>
                      <UserSelect
                          id="driver-select"
                          name="userId"
                          value={userId}
                          roleNames={[]}
                          onChange={option => {
                            setUserId(option);
                          }}
                          placeholder="Select User"
                          className="mb-0"
                          classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col md={ 2 }></Col>
                    <Col md={4} className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        className="me-1"
                        onClick={handleOnAddNew}
                      >
                        <i className="fas fa-plus" /> Add New
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Collapse isOpen={false}>
                  <CardBody className="pb-1"></CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col md={12}>
              {loading ? (
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                items && (
                  <WalletTable
                    items={items}
                    itemCount={itemCount}
                    page={page}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onEdit={handleOnEdit}
                    onConfirmDelete={handleConfirmDelete}
                    onChangePage={(page) => setPage(page)}
                    onPageSizeChange={(pageSize) => {
                      setPage(1);
                      setPageSize(pageSize);
                    }}
                  />
                )
              )}
              <ModalForm
                title="Add Wallet"
                isOpen={modalAddIsOpen}
                toggle={() => setModalAddIsOpen(!modalAddIsOpen)}
                onSubmit={handleSubmit}
              />
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={modalConfirmDeleteIsOpen}
                toggle={() => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen)}
                onSubmit={handleDelete}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

WalletList.propTypes = {
  wallet: PropTypes.object,
  getPagingWallet: PropTypes.func,
};

const mapStateToProps = ({ wallet }) => ({
  wallet,
});

export default withRouter(
  connect(mapStateToProps, {
    getPagingWallet,
    addWallet,
    updateWallet,
    getWalletById,
    deleteWallet,
  })(WalletList)
);
